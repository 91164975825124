import { BuyOrder } from 'hooks/modules/BuyOrder'
import { buyNft } from 'hooks/RaidConfig'
import MyContext from '../common/CommonProvider'
import FastPayConfirm from '../common/FastPayConfirm'
import FastPaySelectCard from '../common/FastPaySelectCard'

import {
  Button,
  Flex,
  Image,
  InjectedModalProps,
  LinkExternal,
  Text,
  useMatchBreakpoints,
  Radio,
  darkColors,
} from '@pancakeswap/uikit'

import React, { useEffect, useRef, useState, useContext } from 'react'
import styled from 'styled-components'

import { Input } from 'antd-mobile'
import useToast from 'hooks/useToast'

const MaxPage = styled.div`
  position: fixed;
  background: #505050c7;
  width: 100vw;
  height: 100vh;
  z-index: 300;
`

const StyledModalContainer = styled.div`
  position: fixed;
  background: url('./assets/img/box2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 30px;
  width: 834px;
  height: 483px;
  margin: auto;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
  @media screen and (max-width: 968px) {
    padding: 10px;
    background: url('./assets/img/box.png');
  }
`

const Row = styled.div`
  width: 100%;
  color: #fff;
  font-size: 20px;
`

const RowCenter = styled.div`
  width: 90%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
`

const RowCenter2 = styled.div`
  background: url('./assets/img/kuang.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 15px;
  height: 15px;
  margin-left: 50px;
  margin-right: 5px;
  margin-top: 2px;
`

const RowRight = styled.div`
  width: 342px;
  height: 344px;
  margin-top: 60px;
  background-image: url('./assets/img/log3.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  margin-left: 30px;
  padding-left: 0px;
  min-height: 364px;
  @media screen and (max-width: 968px) {
    width: 97%;
    height: 0px;
    padding-left: 0px;
    padding-top: 10px;
    margin-left: 1%;
    margin-top: 0px;
  }
`
const RowRightButton = styled(Button)`
  background: url('./assets/img/button.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 80%;
  margin-left: 8.5%;
  height: 40px;
`

const PhoneDiv = styled.div`
  width: 50%;
  display: flex;
  @media screen and (max-width: 968px) {
    display: none;
  }
`

const DivPay = styled.div`
  width: 40%;
  display: flex;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 3px;
  margin-left: 2.5%;
  font-size: 12px;
`

const DivPayRaidoOut = styled.div`
  margin-left: 2.5%;
  margin-top: 6px;
  appearance: none; /* 隐藏默认样式 */

  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  cursor: pointer;
`

const DivPayRaido = styled.input`
  appearance: none; /* 隐藏默认样式 */
  border-radius: 50%;
  margin-left: 2.8px;
  margin-top: 3.2px;

  width: 10px;
  height: 10px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  /* 当选中时，修改背景颜色和边框颜色为蓝色 */
  :checked {
    background-color: #00ffff;
    border-color: #00ffff;
  }
`

const Tooltip = styled.div<{ viewTip: boolean }>`
  color: red;
  display: inline-block;
  background: url('./assets/img/qipao.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 120px;
  height: 30px;
  font-size: 12px;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 3px;

  ${({ viewTip }) =>
    viewTip &&
    `
     transition: visibility 0s, opacity 0.6s linear;
    `}
`

interface ModalProps extends InjectedModalProps {
  setPayCardView
  buyorder
}

const PayCard: React.FC<ModalProps> = ({ setPayCardView, buyorder }) => {
  const { toastSuccess, toastError } = useToast()
  const { isMobile } = useMatchBreakpoints()
  const [lodding, setLodding] = useState(false)
  //倒计时  start
  const [time, setTime] = useState(0) //倒计时时间
  const timeRef = useRef(null) //设置延时器
  const [radio, setRadio] = useState('fastpay')

  const { setPayConfrimView } = useContext(MyContext)
  const [viewTip, setViewTip] = useState(false)
  const [viewGou, setViewGou] = useState(true)
  const [link, setLink] = useState('')

  const [orderId, setOrderId] = useState('')

  const [cardSelectView, setCardSelectView] = useState(false)

  const handleChange = (evt) => {
    console.info('fired')
    const { value } = evt.target
    setRadio(value)
  }

  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1)
      }, 1000)
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [time])

  console.log('time', time)

  const fastByBox = async () => {
    if (!viewGou) {
      //
      setViewTip(true)
      setTimeout(() => {
        setViewTip(false)
      }, 4000)
      return
    }
    setCardSelectView(true)
  }

  const buyBox = async (selectedCard) => {
    const buyOrder = new BuyOrder()
    setViewTip(false)

    if (!viewGou) {
      //
      setViewTip(true)
      setTimeout(() => {
        setViewTip(false)
      }, 4000)
      return
    }

    //直接支付

    if (radio == 'account') {
      //账户余额支付
      buyOrder.type = 2
    }

    //快捷支付

    if (radio == 'fastpay') {
      //快捷支付
      buyOrder.type = 3
    }

    buyOrder.bindCard = selectedCard

    buyOrder.buyNumber = Number(buyorder.buyNumber)

    const result = await buyNft(buyOrder)

    if (result == '请先登录!') {
      toastError('error', result)
      return
    }

    if (result == '请刷新界面再试！') {
      toastError('error', result)
      return
    }

    if (!result?.success) {
      toastError('error', result.message)
    } else {
      /*       toastSuccess('下单成功，跳转支付中!') */
      if (result?.result?.buyOrder?.payNumber) {
        if (result?.result?.buyOrder?.type == 0) {
          //支付成功赠加确认界面

          setPayConfrimView(result?.result?.buyOrder?.id)
          localStorage.setItem('payConfrim', result?.result?.buyOrder?.id)
          if (isMobile) {
            setTimeout(() => {
              location.href = result?.result?.buyOrder?.payUrl
            }, 1000)
          } else {
            //pc支付
            location.href =
              '#/PayCardPc?t=' +
              result?.result?.buyOrder?.payUrl +
              '&p=' +
              result?.result?.buyOrder?.payNumber
          }
          // location.href = result?.result?.buyOrder?.payUrl
        } else if (result?.result?.buyOrder?.type == 2) {
          toastSuccess('支付成功！')
          setPayCardView(0)
        } else if (result?.result?.buyOrder?.type == 3) {
          setOrderId(result?.result?.buyOrder?.id)
        }
      }
    }
  }

  return (
    <MaxPage>
      <StyledModalContainer>
        {orderId && (
          <FastPayConfirm
            orderId={orderId}
            onDismiss={() => {
              setOrderId('')
            }}
          />
        )}

        {cardSelectView && (
          <FastPaySelectCard
            onDismiss={(cardId: string) => {
              setCardSelectView(false)
              console.log("bindcardss",cardId)
              if(cardId === 'bindcard'){
                console.log("bindcard",cardId)
                setPayCardView(0)
              }else if (cardId) {
                buyBox(cardId)
              }
            }}
          />
        )}
        <Row>
          <Image width={1} height={1} src={'./assets/img/box3.png'} />
          <Image
            style={{ float: 'right', top: '-25px', right: '0px' }}
            width={30}
            height={30}
            src={'./assets/img/close.png'}
            onClick={() => {
              console.log('login--view')
              setPayCardView(0)
            }}
          />
        </Row>
        {isMobile && (
          <Image
            mt={3}
            style={{ width: '100%' }}
            width={498}
            height={60}
            src={'./assets/img/hii.png'}
          />
        )}
        <Flex style={{ overflow: 'hidden' }}>
          <PhoneDiv>
            <Image
              mt={80}
              width={400}
              height={400}
              src={'./assets/img/600x600G.gif'}
            />
          </PhoneDiv>

          <RowRight>
            <Row
              style={{
                marginTop: '70px',
                textAlign: 'center',
                display: 'flex',
              }}
            >
              <div style={{ width: '30%', marginLeft: '18%' }}> 商品数量</div>:
              <div
                style={{ width: '48%', textAlign: 'left', marginLeft: '5%' }}
              >
                {buyorder?.buyNumber}
              </div>
            </Row>
            <Row
              style={{
                marginTop: '5px',
                textAlign: 'center',
                display: 'flex',
              }}
            >
              <div style={{ width: '30%', marginLeft: '18%' }}> 应付金额</div>:
              <div
                style={{ width: '48%', textAlign: 'left', marginLeft: '5%' }}
              >
                {buyorder?.payNumber}
              </div>
            </Row>
            <Row
              style={{
                display: 'flex',

                marginTop: '5px',
                paddingLeft: '12%',
              }}
            >
              您已享受星球
              <Text color="#00ec74" fontSize="20px">
                {(
                  ((buyorder?.buyNumber * 3999 - buyorder?.payNumber) * 100) /
                  (buyorder?.buyNumber * 3999)
                ).toFixed(2)}
                %
              </Text>
              优惠奖励
            </Row>

            <RowCenter style={{ marginTop: '5px', display: 'flex' }}>
              <DivPayRaidoOut>
                <DivPayRaido
                  type={'radio'}
                  name="md"
                  width={10}
                  height={10}
                  value="fastpay"
                  onChange={handleChange}
                  checked={radio === 'fastpay'}
                />
              </DivPayRaidoOut>
              <DivPay
                onClick={() => {
                  setRadio('fastpay')
                }}
              >
                <Image
                  style={{ marginTop: '3px' }}
                  width={30}
                  height={20}
                  src={'./assets/img/pay1.png'}
                />
                <Text style={{}} ml={1} mr={1}>
                  银行卡支付
                </Text>
              </DivPay>

              <DivPayRaidoOut>
                <DivPayRaido
                  type={'radio'}
                  name="md"
                  value="account"
                  onChange={handleChange}
                  checked={radio === 'account'}
                />{' '}
              </DivPayRaidoOut>
              <DivPay
                onClick={() => {
                  setRadio('account')
                }}
              >
                <Image width={30} height={25} src={'./assets/img/pay2.png'} />
                <Text ml={1}>账户余额</Text>{' '}
              </DivPay>
            </RowCenter>

            <RowCenter style={{ marginTop: '5px', display: 'flex' }}>
              <DivPayRaidoOut>
                <DivPayRaido
                  type={'radio'}
                  name="md"
                  width={10}
                  height={10}
                  value="alipay"
                  onChange={handleChange}
                  checked={radio === 'alipay'}
                />
              </DivPayRaidoOut>
              <DivPay
                onClick={() => {
                  setRadio('alipay')
                }}
              >
                <Image
                  width={22}
                  height={22}
                  src={'./assets/img/zhifubao.png'}
                />
                <Text style={{}} ml={2} mr={1}>
                  支付宝支付
                </Text>
              </DivPay>
            </RowCenter>

            <Row
              style={{
                textAlign: 'center',
                display: 'flex',
                height: '30px',
                marginTop: '10px',
              }}
            >
              <RowCenter2
                onClick={() => {
                  setViewGou(!viewGou)
                }}
              >
                {viewGou && (
                  <Image width={15} height={15} src={'./assets/img/gou.png'} />
                )}
              </RowCenter2>
              <a
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '12px',
                }}
                target={'_blank'}
                href="https://doc.wasai.space/1.html"
              >
                《阅读并同意用户购买协议》
              </a>
              {viewTip && <Tooltip viewTip={viewTip}>请阅读并勾选协议</Tooltip>}
            </Row>

            <Row>
              <RowRightButton
                onClick={() => {
                  if (radio == 'fastpay') {
                    //快捷支付
                    fastByBox()
                  } else {
                    buyBox('')
                  }
                }}
              >
                确认付款
              </RowRightButton>
            </Row>
          </RowRight>
        </Flex>
      </StyledModalContainer>
    </MaxPage>
  )
}

export default PayCard
