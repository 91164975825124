import React, { Component ,useState,  useEffect} from 'react'
import { ToastListener, ToastsProvider } from './contexts/ToastsContext'
import { CommonProvider } from './view/common/CommonProvider'

import { dark } from '@pancakeswap/uikit'
import { SendSms, base36_to_num, num_to_base36 } from 'hooks/RaidConfig'
import { 
  useMatchBreakpoints 
} from '@pancakeswap/uikit'
import { ThemeProvider } from 'styled-components'

import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { parse } from 'querystring'
import lanhu_huaban3 from './view/lanhu_huaban3/index.jsx'
import lanhu_huaban4 from './view/lanhu_huaban4/index.jsx'
import lanhu_huaban1 from './view/lanhu_huaban1/index.tsx'
import lanhu_huaban2 from './view/lanhu_huaban2/index.tsx'
import Home from './view/Home/index.tsx'
import Info from './view/Info/index.tsx'

import SplashScreen from './view/space'

import {   WASAI_URL } from 'hooks/RaidConfig'


import PayCardPc from './view/lanhu_huaban2/PayCardPc.tsx'

const StyledThemeProvider = (props) => {
  return <ThemeProvider theme={dark} {...props} />
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enterView: true
    };
  }

  handleEnterButtonClick = () => {
    this.setState({ enterView: false }); // 使用 this.setState 更新状态
  };
  render() {
    console.log('very good init')
    const { enterView } = this.state;

    /* 获取链接 */
    console.log('vlogggggg', num_to_base36(1))

    const getPageQuery = () => parse(window.location.href.split('?')[1])

    const locationQuery = getPageQuery()
    if (locationQuery && locationQuery.invite) {
      // 读取base64字符串
      let buffer = locationQuery.invite.toString()

      console.log('invite_addressbuffer', buffer)
      // 进行解码
      localStorage.setItem('invite_address', buffer.toString())
    }

    /* 获取链接 */

  //  const { isMobile } = useMatchBreakpoints()


 
    const phones = localStorage.getItem('userInfo_phone')
   



 
 
      // 获取当前完整的 URL
      const fullUrl = window.location.href;
  
      // 获取问号后面的查询参数
      const queryParams = window.location.search;
  
      // 使用 URLSearchParams 对象解析查询参数
      const urlSearchParams = new URLSearchParams(queryParams);
  
      // 使用 toString() 方法构建不带查询参数的 URL
      const urlWithoutParams = fullUrl.replace(queryParams, '');
      const currentDomain = window.location.hostname;

 

     console.log("urlWithoutParamsurlWithoutParamsurlWithoutParams",urlWithoutParams, WASAI_URL==urlWithoutParams)

    return (
      <HashRouter>
        <CommonProvider>
          <ToastsProvider>
            <StyledThemeProvider>
              <div>
                <Switch>
                
                {/*  {enterView&&!phones&&WASAI_URL==urlWithoutParams&&<SplashScreen  setEnterView={this.handleEnterButtonClick}/>}    */} 
                  <Route
                    exact
                    path="/lanhu_huaban1"
                    component={lanhu_huaban1}
                  />
                  <Route
                    exact
                    path="/lanhu_huaban3"
                    component={lanhu_huaban3}
                  />
                  <Route
                    exact
                    path="/lanhu_huaban4"
                    component={lanhu_huaban4}
                  />
                  <Route
                    exact
                    path="/lanhu_huaban2"
                    component={lanhu_huaban2}
                  />
                   <Route
                    exact
                    path="/Home"
                  /*   component={Home} */
                   component={lanhu_huaban2}
                  />
                   <Route
                    exact
                    path="/Info"
                    component={Info}
                  />
                  <Route exact path="/PayCardPc" component={PayCardPc} /> 
                  <Redirect from="/" to="/Home" />
                </Switch>
              </div>
              <ToastListener />
            </StyledThemeProvider>
          </ToastsProvider>
        </CommonProvider>
      </HashRouter>
    )
  }
}

export default App
