import { buyNft, queryTotalNft, wasaiPerson } from 'hooks/RaidConfig'
import MyContext from '../common/CommonProvider'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import {
  Button,
  Flex,
  Image,
  InjectedModalProps,
  Progress,
  Text,
  useMatchBreakpoints, 
} from '@pancakeswap/uikit'

import React, {
  Component,
  useContext,
  useEffect,
  useState
} from 'react'

import {
  Input
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import styled, { createGlobalStyle, css } from 'styled-components'

import Login from '../lanhu_huaban2/LoginNew'

import CommonHead from '../lanhu_huaban2/CommonHead2'
import Footer from '../common/Footer'
import PayCard from '../lanhu_huaban2/PayCard'
import PayConfirm from '../lanhu_huaban2/PayConfirm'
import PayConfirmDeposit from '../lanhu_huaban2/PayConfirmDeposit'

interface ModalProps extends InjectedModalProps { }
const IdoStyle = createGlobalStyle<{ loginView: number }>`

html,body{
    
}

 body {
  background: url('./assets/img/buybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
  
}
#root{
  display: block; /* 或 inline 或 inline-block，视具体情况而定 */

  min-width: auto;
  max-width: 100%;
}
/*
.jsfPFA{
  background:#d331bb;
}
 
.irXTms{
  background:#d331bb;
}*/

${({ loginView }) =>
    loginView == 0 &&
    css`
    .adm-input-element {
      color: #fff !important;
      text-align: center;
    }
  `}



`

const StyledPage = styled.div`
  min-height: 960px; 
  padding-top: 0;
  padding-bottom: 16px;
  overflow-y: auto;
   
  background: url('./assets/img/buybg.png') center/cover no-repeat;
  min-width: auto;
  max-width: 100%; 
`;

const BuyBoxBox = styled.div`
  width: 100%;
  background: url('./assets/img/pc.jpg') center/cover no-repeat;
  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;

  
`;

const BuyBoxLeft = styled.div`
  width: 100%;
  padding-top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;

 
    padding-top: 145px;
 
`;
const BuyBoxRight = styled.div`
width: 100%;
position: absolute;
overflow: hidden;
margin-top: 75px;
top: 520px;
@media screen and (max-height: 1000px) {
  top: 460px;
}
 
`;

const Row = styled.div`
 
    width: 100%;
 
`

const RowFot = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const RowText2 = styled.div`
  margin-top: 10px;
  margin-buttom: 10px;
  margin-left: 50px;
 
    width: 100%;
    margin-top: 10px;
    margin-buttom: 10px;
    margin-left: 0px;
 
`



const RowFirst2 = styled.div`
 
    width: 360px;
    margin: auto;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    padding-right: 2.5%; 
    padding-left: 2.5%;
    @media screen and (max-height: 1000px) {
      width: 310px;
    }
  
`
const BuyBoxLe = styled.div`
  
    display: block;
 
`

 


const RowTotal = styled.div`
  background: url('./assets/img/34cx.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  min-height: 182px;

  margin-top: 80px;
  width: 555px;
  margin-left: -20px; 
 
    width: 100%;
    margin: auto;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0; 
    border-radius: 5px;
 
`

const RowRightENDButton = styled(Button)`
  
  background: rgb(211, 49, 187);
 
   border-radius: 8px;
    height: 36px;
    margin-top: 0px;  
    width: 50%;
    margin-left: 10px;
    font-size:19px;
 
`


const StyledFoot = styled.div`
  width: 80%;
  overflow-y: auto;
  min-height: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
  padding-top: 50px;
`


const RowPROCESS = styled.div` 
    width: 100%;
    margin: auto;  
    border-radius: 5px; 
 
`

const RowPROCESS2 = styled.div`
     transform:scale(0.6);
     margin-left:35%;
`

const RowPROCESS3 = styled.div`
width: 90%; 
margin-left:5%;
`

const StyleInput = styled(Input)`
transform:scale(0.6);
  width: 80px;
  height:15px;
  max-height:15px;
  font-size:14px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff85;
  text-align: center;
  color: #fff !important;
  font-color: #fff; 
 
`


interface IndividualNFTPageProps { }

export const PC: React.FC = ({ }) => {
  const [loginView, setLoginView] = useState(0)
  const [payCardView, setPayCardView] = useState(0)

  const [readView, setReadView] = useState(true)
  const [buyorder, setBuyorder] = useState<any>()
  const [wasaiInvite, setWasaiInvite] = useState<any>()
  const [nftNumber, setNftNumber] = useState('1')
  const [tips, setTips] = useState('')
  const [progress, setProgress] = useState(1)
  const { isMobile } = useMatchBreakpoints()

  const {
    payDespointConfrimView,
    payConfrimView,
    teamCardView,
    setTeamCardView,
  } = useContext(MyContext)

  let nftRate = 1

   

  const { toastSuccess, toastError } = useToast()
  const [types, setTypesView] = useState(1)

  const [totalNft, setTotalNft] = useState(0)

  /*购买数量 */

  const onInputChangeNum = (e) => {
    setNftNumber(e.replace(/[^\d]/g, ''))
  }

  

 

  console.log('payConfirm-ppp', payConfrimView)

  const queryData = async () => {



    const result = await wasaiPerson()
    if (result?.result) {
      setWasaiInvite(result?.result?.wasaiInvite) 
    }
  }

  useEffect(() => {
    queryData()
    setProgress(100) 
  }, [])


  
 

  if (Number(nftNumber) >= 5 && Number(nftNumber) < 70) {
    nftRate = 0.6668
  } else if (Number(nftNumber) >= 70) {
    nftRate = 0.4763
  } /* else if (Number(nftNumber) > 910) {
      nftRate = 0.3664
    } */

  if (wasaiInvite?.userLevel == 2) {
    if (Number(nftNumber) >= 1 && Number(nftNumber) < 70) {
      nftRate = 0.6668
    } else if (Number(nftNumber) >= 70) {
      nftRate = 0.4763
    } /* else if (Number(nftNumber) > 910) {
      nftRate = 0.3664
    } */
  }

  if (wasaiInvite?.userLevel == 3) {
    if (Number(nftNumber) >= 1) {
      nftRate = 0.4763
    } /* else if (Number(nftNumber) > 910) {
      nftRate = 0.3664
    } */
  }

  if (wasaiInvite?.userLevel == 4) {
    if (Number(nftNumber) >= 1) {
      nftRate = 0.3664
    } /* else if (Number(nftNumber) > 910) {
      nftRate = 0.3664
    } */
  }

  if (wasaiInvite?.userLevel == 5) {
    if (Number(nftNumber) >= 1) {
      nftRate = 0.28745
    } /* else if (Number(nftNumber) > 910) {
      nftRate = 0.3664
    } */
  }

  const buyBox = async () => {
    const buyOrder = new BuyOrder()
    //测算
    buyOrder.type = 1

    buyOrder.buyNumber = Number(nftNumber)

    const result = await buyNft(buyOrder)

    if (result == '请先登录!') {
      toastError('error', result)
      return
    }

    if (result == '请刷新界面再试！') {
      toastError('error', result)
      return
    }

    if (!result?.success) {
      toastError('error', result.message)
    } else {
      /*       toastSuccess('下单成功，跳转支付中!') */
      if (result?.result?.buyOrder?.payNumber) {
        setBuyorder(result?.result?.buyOrder)
        setPayCardView(1)
        // location.href = result?.result?.buyOrder?.payUrl
      }
    }
  }

  return (
    <>
      <StyledPage>
        <IdoStyle loginView={loginView} />
        {loginView >= 1 && (
          <Login setLoginView={setLoginView} typesss={loginView} />
        )}

{payCardView >= 1 && (
          <PayCard setPayCardView={setPayCardView} buyorder={buyorder} />
        )}

        {payConfrimView && (
          <PayConfirm buyorderId={payConfrimView} payView={true} />
        )}

        {payDespointConfrimView && (
          <PayConfirmDeposit depositId={payDespointConfrimView} />
        )}


        <CommonHead setLoginView={setLoginView} loginView={loginView} />


        <BuyBoxBox
          style={{
            height: '740px',

            minHeight:  '862px' 
          }}
        >
          <BuyBoxLe>
            <BuyBoxLeft>
              <Image
                style={{
                  borderRadius: '20px', 
                }}
                width={315}
                height={315}
                src={'./assets/img/home5.gif'}
              />
            </BuyBoxLeft>

            <BuyBoxRight>

              <RowFirst2>
                <RowTotal>
                  <RowText2>
                    <Text 
                     fontSize="16px"
                     fontWeight={600}
                      color="#fff"
                      pt={3} ml={3}
                    >
                      {'数字藏品'}
                    </Text>

                    <RowPROCESS>
                      <RowPROCESS2>
                        <Flex>
                          <Text>剩余数量：</Text>
                          <Text>
                            <Flex> 
                              <Text color="#7eff94">{'100,000'} </Text>
                              /100,000
                            </Flex>
                          </Text>
                        </Flex>
                      </RowPROCESS2>
                      <RowPROCESS3>
                        <Progress   variant={'flat'} primaryStep={progress} />
                      </RowPROCESS3>
                    </RowPROCESS>
                    <Text
                      fontSize="16px"
                      fontWeight={600}
                      color="#fff"
                      pt={2} ml={3} 
                    > 
                      {'购买'}
                    </Text>

                    
                      <Flex style={{ transform:"scale(0.84)"}}> 
                      <Text   ml={3} fontSize={  '20px'} fontWeight={600}>
                        {(3999 * nftRate*Number(nftNumber)).toFixed(0)} RMB
                      </Text>

                      <Image ml={4} mt={1}
                        onClick={() => {
                          if (Number(nftNumber) > 1) {
                            setNftNumber(Number(nftNumber) - 1 + '')
                          }
                        }} 
                        width={15}
                        height={15}
                        src={'./assets/img/subtract.png'}
                      />
                      <StyleInput  
                        style={{ color: '#ffffff' }}
                        value={nftNumber}
                        onChange={onInputChangeNum}
                      />
                      <Image  mt={1}
                        onClick={() => {
                          setNftNumber(Number(nftNumber) + 1 + '')
                        }}
                       
                     
                        width={15}
                        height={15}
                        src={'./assets/img/add.png'}
                      />
                    </Flex>
                    
                   
                  </RowText2>
                
                  <Flex style={{marginTop:"30px"}}> <Text pt={2}  ml={3} fontSize={  '16px'} fontWeight={600}>
                  流通价格：
                      </Text>
                  <RowRightENDButton  >29999RMB</RowRightENDButton>

                  </Flex> 
                </RowTotal>
              </RowFirst2>
            </BuyBoxRight>
          </BuyBoxLe>
        </BuyBoxBox>




         <Footer />
      </StyledPage>
    </>
  )
}
 

export default PC
